import React, { Component } from "react";
import styled from "styled-components";
// import Header from "./components/Header";

const Container = styled.div`
  padding: 24px 16px;
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 8px;
  margin: 0 auto;
  max-width: 800px;
  h1 {
    font-size: 40px;
    line-height: 48px;
    font-weight: 600;
  }
  h2 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 400;
  }
  p {
    font-size: 18px;
    line-height: 32px;
    font-weight: 400;
  }
  img {
    width: 160px;
    margin-bottom: 24px;
  }
`;

class App extends Component {
  render() {
    return (
      <React.Fragment>
        {/* <Header /> */}
        <Container>
          <img src="./avatar.png" alt="" />
          <h1>I'm Bob Muller</h1>
          <h2>a product designer from the netherlands — nice to meet you</h2>
        </Container>
        <Container>
          <p>
            Nothing to see here yet, I'm sorry for that. Working hard to make
            this more interesting. If you really need to find me you can say hi
            <a href="https://twitter.com/bobmatteo"> @bobmuller</a>.
          </p>
        </Container>
      </React.Fragment>
    );
  }
}

export default App;
